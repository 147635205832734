<template>
  <div class="container wrapper">
    <div class="content">
      <BaseLoad v-if="loading" />
      <div class="con" v-else>
        <!-- leve详情 -->
        <div class="flex item">
          <img class="item-l" :src="preSrc + info.cover" alt="" />
          <div class="item-r">
            <h3 class="ellipsis" :title="info.name">{{ info.name }}</h3>
            <p class="desc">{{ info.desc }}</p>
            <div class="item-r-b between">
              <div>
                <span class="price">￥{{ info.price }}</span>
                <del class="del-price">{{ info.original_price }}</del>
              </div>
              <div class="flex">
                <button class="btn" v-if="info.buy_status == 1">
                  <i class="iconfont icon-duihao"></i> 已购买
                </button>
                <button class="btn" v-else @click="toPurchase(info.game_id, 1)">
                  立即购买
                </button>
              </div>
            </div>
            <div class="between favor-work">
              <div class="flex">
                <CancelFavor
                  v-if="info.collect_status == 1"
                  :cId="info.game_id"
                  :cStatus="2"
                  :fontSize="20"
                  @favorCancel="handleFavorCanel"
                />
                <BaseFavor
                  v-else
                  :cId="info.game_id"
                  :cStatus="1"
                  :fontSize="20"
                  @favorStatus="handleFavor"
                />
              </div>
              <div class="flex">
                <button
                  class="preview btns preview-btn flex"
                  @click.stop="previewCreateGame(info.game_id)"
                >
                  <i class="iconfont icon-yulan"></i>
                  <span>预览模板</span>
                </button>
                <button
                  class="btns create-btn"
                  @click.stop="createGame(info.game_id, info.buy_status)"
                >
                  <i class="iconfont icon-bianji1"></i>
                  创建游戏
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- 游戏详情 -->
        <div class="course-title" v-if="info.content">
          <p class="line"></p>
          <div class="title">
            <img src="@/assets/img/course/level-title.png" alt="" />
            <span>游戏详情</span>
          </div>
        </div>
        <div class="lesson-con" v-html="info.content"></div>
      </div>
    </div>
    <BaseCopyright />
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import {handlePreviewCreate} from '@/tools/GlobalFunction'
export default {
  components: {
    BaseEmpty,
    BaseLoad,
  },
  data() {
    return {
      preSrc: config.src,
      info: {},
      list: [],
      loading: true,
    };
  },
  created() {
    let _self = this;
    let id = _self.$route.params.id;
    _self.initData(id);
  },
  methods: {
    // 初始化
    initData(id) {
      let _self = this;
      _self.axios
        .post(config.url.gameInfo, _self.$qs.stringify({ game_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.info = res.data;
            _self.loading = false;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 预览
    previewCreateGame(gameId, configId = "", title = '') {
      let _self = this;
      handlePreviewCreate(gameId, configId, title, _self);
      
    },
    // 创建游戏
    createGame(id, status) {
      let _self = this;
      if(status == 2){
        _self.showTip('warning', '购买后才能创建游戏哦', '1500');
        return;
      }
      _self.$router.push({
        name: "game-create",
        params: {
          id: id,
        },
      });
    },
    // 取消收藏
    handleFavorCanel(status) {
      let _self = this;
      _self.info.collect_status = status;
    },
    // 收藏
    handleFavor(status) {
      let _self = this;
      _self.info.collect_status = status;
    },
    
    toPurchase(id, type) {
      let _self = this;
      _self.$router.push({
        name: "purchase",
        params: {
          id: id,
          type: type,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content{
  background: #fff;
}
.con {
  .item {
    background: #fff;
    box-sizing: border-box;
    margin: 60px auto;
    .item-l {
      width: 450px;
      height: 300px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: $border-radius-main;
      object-fit: contain;
    }
    .item-r {
      width: 750px;
      height: 300px;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 10px 30px 20px;
      box-sizing: border-box;
      border-left: 0;
      border-radius: 0 10px 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .desc {
        font-size: 18px;
        color: $font-color-51;
        line-height: 30px;
        height: 60px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; //行数
        -webkit-box-orient: vertical;
      }
      .item-r-b {
        background: #f9f9f9;
        border-radius: 5px 20px 20px 5px;
      }
      .price {
        font-size: 30px;
        color: $color-main;
        font-weight: bold;
        margin: 0 20px 0 30px;
      }
      .del-price {
        font-size: 18px;
        color: $font-color-9f;
      }
      .itme-r-b {
        background: $font-color-9f;
      }

      .icon-duihao {
        margin-right: 5px;
      }
    }
  }
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .favor,
  .work img,
  .btn:hover,
  .btns:hover {
    cursor: pointer;
  }
  .favor-work {
    color: $font-color-9f;
    margin-top: 45px;
    .slash {
      margin: 0 15px;
    }
    .work img {
      margin-right: 10px;
      display: inline-block;
      width: 18px;
      height: 18px;
      object-fit: contain;
    }
    .favor {
      margin-top: -3px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
    .btns {
      width: 160px;
      height: 50px;
      line-height: 50px;
      border-radius: 30px;
      font-size: 20px;
      margin-right: 30px;
    }
    .btns:last-of-type {
      margin-right: 0;
    }
    .preview-btn {
      color: #34c3f9;
      border: 1px solid #34c3f9;
    }
    .create-btn {
      color: #03cca6;
      border: 1px solid #03cca6;
    }
    .icon-yulan,
    .icon-bianji1 {
      margin-right: 5px;
      font-size: 18px;
    }
  }
  .course-title {
    position: relative;
    margin-bottom: 80px;
    .line {
      width: 100%;
      height: 10px;
      background: #f9f9f9;
      border-radius: 5px;
    }
    .title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -8px;
      img {
        width: 310px;
        height: 60px;
      }
      span {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        color: #fff;
      }
    }
  }
}
</style>
